




























































































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
// @ts-ignore
export default class Task extends Vue {
  pagerHeader: any = {
    title: '任务日志列表',
    desc: '任务日志列表',
    breadcrumb: ['任务日志列表', '代理管理'],
  }

  loading: boolean = false
  filter: any = {
    memberId: '',
    reasons: null,
    startDate: null,
    endDate: null,
    merchantId: '',
    keywords: '',
    page: 1,
    size: 10,
    sortField: null,
    sortDirection: null,
  }
  reasonValues: any = []
  dateRange: any = []
  tableData: any = {
    items: [],
    totalCount: 0,
  }
  created() {
    this.readerPage()
  }
  // 改变多少条一页
  handleSizeChange(val: number) {
    this.filter.size = val
    this.readerPage()
  }
  // 点击第几页
  handleCurrentChange(val: number) {
    this.filter.page = val
    this.readerPage()
  }
  async readerPage() {
    // this.loading = true;
    // try {
    //   let res = await this.$ajax.post(
    //     "",
    //     {
    //       ...this.filter
    //     }
    //   );
    //   if (res.data.success) {
    //     this.tableData = res.data.result;
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
    // this.loading = false;
  }
  //  搜索
  search() {
    this.filter.page = 1
    this.readerPage()
  }
}
